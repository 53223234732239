import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = extendTheme({
  colors: {
    gray: {
      50: '#FAFAFD',
      100: '#DADBDC',
      200: '#C3C4C6',
      300: '#ABADB0',
      400: '#949699',
      500: '#7C8083',
      600: '#636669',
      700: '#2e2d33',
      800: '#2c2d2e',
      900: '#26262b',
    },
    primary: {
      50: '#FDEDE7',
      100: '#FBCBBC',
      200: '#F8AA91',
      300: '#F58966',
      400: '#F2683B',
      500: '#EF4610',
      600: '#BF380D',
      700: '#902A09',
      800: '#601C06',
      900: '#300E03',
    },
  },
});
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
