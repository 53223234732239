import './App.css';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ServicePage from './components/ServicePage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import { motion, useScroll } from 'framer-motion';
import { useColorMode } from '@chakra-ui/react';

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  const { scrollYProgress } = useScroll();
  return (
    <div className="App" style={{ background: isDark ? '#26262b' : '#fafafd' }}>
      <div
        style={{
          zIndex: 10,
          background: isDark ? '#323334' : '#fafafd',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '5px',
        }}
      >
        <motion.div
          style={{
            scaleX: scrollYProgress,
            zIndex: 1000,
            background: '#F15A29',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: '5px',
            transformOrigin: '0%',
          }}
        ></motion.div>
      </div>
      <Header toggleColorMode={toggleColorMode} isDark={isDark} />
      <HomePage />
      <ServicePage />
      <AboutPage />
      <ContactPage />
    </div>
  );
}

export default App;
