import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import ImgWebDes from '../images/website-design.svg';
import ImgWebDev from '../images/website-dev.svg';
import ImgLogoDes from '../images/logo-design.svg';
import ImgCloud from '../images/cloud-service.svg';

import { useRef } from 'react';
import { useInView } from 'framer-motion';

const ServicePage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const ref1 = useRef(null);
  const isInView1 = useInView(ref1, { once: true });
  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="services"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: '3rem' }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-l, #2F3B84, #F15A29)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? 'none' : 'translateY(100px)',
          opacity: isInView ? 1 : 0,
          transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
      >
        Services
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={10}
        data-cues="slideInUp"
        ref={ref1}
      >
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? 'none' : 'translateX(-200px)',
            opacity: isInView1 ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
          borderRadius="lg"
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Website Development
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={['column', 'column', 'row', 'row']}
              gap={10}
            >
              <Box flexBasis={['100%', '100%', '40%', '40%']}>
                <Image src={ImgWebDes} alt="Ospirant" />
              </Box>
              <Box flexBasis={['100%', '100%', '60%', '60%']}>
                <Text color="gray.500">
                  We design responsive websites that allow your business to be
                  easily found by potential customers through search engines and
                  social media. Best practices for 24/7 availability of your
                  business,a cost-effective way to reach a large audience.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? 'none' : 'translateX(200px)',
            opacity: isInView1 ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
          borderRadius="lg"
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Custom Web Applications
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={['column', 'column', 'row', 'row']}
              gap={10}
            >
              <Box flexBasis={['100%', '100%', '40%', '40%']}>
                <Image src={ImgWebDev} alt="Ospirant" />
              </Box>
              <Box flexBasis={['100%', '100%', '60%', '60%']}>
                <Text color="gray.500">
                  We build Scalable, Cloud-based, Secure applications with
                  cutting edge technologies to manage your business on a click.
                  Compatible with a wide range of devices and platforms,
                  allowing users to access them from any device with a web
                  browser.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? 'none' : 'translateX(-200px)',
            opacity: isInView1 ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
          borderRadius="lg"
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Branding Services
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={['column', 'column', 'row', 'row']}
              gap={10}
            >
              <Box flexBasis={['100%', '100%', '40%', '40%']}>
                <Image src={ImgLogoDes} alt="Ospirant" />
              </Box>
              <Box flexBasis={['100%', '100%', '60%', '60%']}>
                <Text color="gray.500">
                  We provide branding solutions helps businesses to establish a
                  strong brand identity. Business Class Logos, Consistant
                  Designs marketing increase the value of a business. Branding
                  services help increase brand awareness and recognition.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card
          p={5}
          boxShadow="md"
          style={{
            transform: isInView1 ? 'none' : 'translateX(200px)',
            opacity: isInView1 ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
          borderRadius="lg"
        >
          <Heading py={5} as="h2" size="md" noOfLines={2} textAlign="center">
            Scalable Cloud Services
          </Heading>
          <CardBody>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              direction={['column', 'column', 'row', 'row']}
              gap={10}
            >
              <Box flexBasis={['100%', '100%', '40%', '40%']}>
                <Image src={ImgCloud} alt="Ospirant" />
              </Box>
              <Box flexBasis={['100%', '100%', '60%', '60%']}>
                <Text color="gray.500">
                  We provide Scalable and Reliable cloud services that deliver
                  high level of uptime and security for your IT applications.
                  Our Cloud services allows a mix-and-match approach, where you
                  can choose the best solutions with flexiblity and stablity.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};
export default ServicePage;
