import {
  Image,
  SimpleGrid,
  Container,
  Stack,
  Heading,
  Button,
  Text,
} from '@chakra-ui/react';
import ImgHome from '../images/home.svg';
import { RiArrowRightLine } from 'react-icons/ri';
import { useState } from 'react';
import { useRef } from 'react';
import { useInView } from 'framer-motion';

const HomePage = () => {
  const [arrowPadding, setArrowPadding] = useState(2);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Container
      maxW="container.xl"
      py="3rem"
      id="home"
      justifyContent="center"
      px={8}
      ref={ref}
    >
      <SimpleGrid
        flexDirection={['row-reverse']}
        columns={{ base: 1, md: 2 }}
        spacing={10}
        minH="75vh"
        pt="6rem"
      >
        <Stack
          spacing={[6, 6, 12, 12]}
          justifyContent="center"
          h="100%"
          style={{
            transform: isInView ? 'none' : 'translateX(-200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
        >
          <Heading
            as="h1"
            size="4xl"
            noOfLines={5}
            bgGradient="linear(to-l, #2F3B84, #F15A29)"
            bgClip="text"
          >
            Thinking of the Future. Building for the Present.
          </Heading>
          <Text color="gray.500" noOfLines={8}>
            We offer a wide range of services including website design and
            development, e-commerce solutions and search engine optimization.
            Our team stays on top of the latest web technologies and design
            trends to ensure that our clients' websites are not only visually
            appealing but also perform well on all devices.
          </Text>
          <Button
            onMouseOver={() => setArrowPadding(8)}
            onMouseOut={() => setArrowPadding(2)}
            w="50%"
            colorScheme="primary"
            size="lg"
            onClick={() => document.getElementById('services').scrollIntoView()}
          >
            Get Started
            <RiArrowRightLine
              id="btnGetStarted"
              size={24}
              style={{ marginLeft: arrowPadding, paddingTop: '2px' }}
            />
          </Button>
        </Stack>
        <Stack
          justifyContent="center"
          h="100%"
          style={{
            transform: isInView ? 'none' : 'translateX(200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
        >
          <Image h="auto" src={ImgHome} alt="Ospirant Home" />
        </Stack>
      </SimpleGrid>
    </Container>
  );
};
export default HomePage;
