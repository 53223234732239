import {
  Image,
  SimpleGrid,
  Container,
  Heading,
  Card,
  CardBody,
  Text,
  Flex,
  Box,
} from '@chakra-ui/react';
import ImgTeam from '../images/team.svg';
import { useRef } from 'react';
import { useInView } from 'framer-motion';

const AboutPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <Container
      maxW="container.xl"
      pt="4rem"
      id="about"
      justifyContent="center"
      px={8}
      css={{ scrollMargin: '3rem' }}
      ref={ref}
    >
      <Heading
        as="h1"
        size="3xl"
        noOfLines={4}
        bgGradient="linear(to-l, #2F3B84, #F15A29)"
        bgClip="text"
        textAlign="center"
        pb={10}
        style={{
          transform: isInView ? 'none' : 'translateY(100px)',
          opacity: isInView ? 1 : 0,
          transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
        }}
      >
        About
      </Heading>
      <SimpleGrid spacing={10}>
        <Card
          boxShadow="md"
          style={{
            transform: isInView ? 'none' : 'translateY(200px)',
            opacity: isInView ? 1 : 0,
            transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
          }}
          borderRadius="lg"
        >
          <CardBody>
            <Flex
              justifyContent="center"
              alignItems="center"
              direction="column"
              gap={10}
            >
              <Box w="50%">
                <Image alignSelf="center" src={ImgTeam} alt="Ospirant Team" />
              </Box>
              <Box p={[0, 0, 5, 5]}>
                <Text color="gray.500" lineHeight={8}>
                  &emsp;&emsp;
                  <mark
                    style={{
                      background: 'transparent',
                      color: '#F15A29',
                      fontWeight: '600',
                    }}
                  >
                    Ospirant Creative Solutions
                  </mark>
                  {'  '}&nbsp; is a leading web development enterprise that
                  specializes in creating custom, responsive and user-friendly
                  websites and web applications for businesses of all sizes. Our
                  team of experienced developers, designers work together to
                  deliver innovative solutions that drive results.
                  <br />
                  <br /> &emsp;&emsp;We understand the importance of a strong
                  online presence in today's digital age, and we are dedicated
                  to helping our clients achieve their goals through the power
                  of the web. We offer a wide range of services including
                  website design and development, e-commerce solutions and
                  search engine optimization. Our team stays on top of the
                  latest web technologies and design trends to ensure that our
                  clients' websites are not only visually appealing but also
                  perform well on all devices. We also place a strong emphasis
                  on creating websites that are easy to navigate and provide a
                  great user experience.
                  <br />
                  <br /> &emsp;&emsp;We pride ourselves on delivering projects
                  on time and on budget, and we work closely with our clients to
                  ensure their complete satisfaction. Whether you're looking to
                  create a new website or revamp an existing one, we are here to
                  help. Contact us today to learn more about how we can help
                  your business succeed online.
                </Text>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
    </Container>
  );
};
export default AboutPage;
