import { useState } from 'react';
import { Flex, IconButton, Link, Image, Tooltip } from '@chakra-ui/react';
import { TbMenu, TbX, TbMoon, TbSun } from 'react-icons/tb';
import LogoDark from '../images/logo-dark.svg';
import LogoLight from '../images/logo-light.svg';

import { useRef } from 'react';
import { useInView } from 'framer-motion';

const Header = ({ toggleColorMode, isDark }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [display, changeDisplay] = useState('none');

  return (
    <Flex
      height={['4.4rem', '4.4rem', '5.2rem', '5.2rem']}
      position="fixed"
      w="100vw"
      justify="center"
      zIndex={10}
      ref={ref}
      style={{
        opacity: isInView ? 1 : 0,
        transition: 'all .6s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
      }}
    >
      <Flex
        mt={[0, 0, '5px', '5px']}
        top="0"
        right="0"
        align="center"
        justify="space-between"
        w="container.xl"
        px={[5, 5, 10, 10]}
        mx={[0, 0, 10, 10]}
        backdropFilter="blur(12px)"
        boxShadow={isDark ? 'none' : 'md'}
        bgColor={isDark ? '#1a1a1fee' : '#ffffffee'}
        borderRadius={['none', 'none', 'xl', 'xl']}
      >
        {/* Desktop */}

        <Link href="/#" display={['none', 'none', 'flex', 'flex']}>
          <Image
            w={150}
            src={isDark ? LogoDark : LogoLight}
            alt="Ospirant Logo"
          />
        </Link>

        <Flex
          justify="space-between"
          gap={20}
          display={['none', 'none', 'flex', 'flex']}
        >
          <Link
            onMouseEnter={e =>
              (e.target.style.background = isDark ? '#2e2d33' : '#EBEBEF')
            }
            onMouseOut={e => (e.target.style.background = 'none')}
            href="#home"
            px={4}
            py={2}
            borderRadius="full"
          >
            Home
          </Link>

          <Link
            href="#services"
            onMouseEnter={e =>
              (e.target.style.background = isDark ? '#2e2d33' : '#EBEBEF')
            }
            onMouseOut={e => (e.target.style.background = 'none')}
            px={4}
            py={2}
            borderRadius="full"
          >
            Services
          </Link>

          <Link
            href="#about"
            onMouseEnter={e =>
              (e.target.style.background = isDark ? '#2e2d33' : '#EBEBEF')
            }
            onMouseOut={e => (e.target.style.background = 'none')}
            px={4}
            py={2}
            borderRadius="full"
          >
            About
          </Link>
          <Link
            href="#contact"
            onMouseEnter={e =>
              (e.target.style.background = isDark ? '#2e2d33' : '#EBEBEF')
            }
            onMouseOut={e => (e.target.style.background = 'none')}
            px={4}
            py={2}
            borderRadius="full"
          >
            Contact
          </Link>
        </Flex>

        {/* Mobile */}
        <IconButton
          variant="outline"
          aria-label="Open Menu"
          icon={<TbMenu />}
          onClick={() => changeDisplay('flex')}
          display={['flex', 'flex', 'none', 'none']}
        />

        <Link href="/#" display={['flex', 'flex', 'none', 'none']}>
          <Image
            w={120}
            src={isDark ? LogoDark : LogoLight}
            alt="Ospirant Logo"
          />
        </Link>
        <Tooltip
          label={isDark ? 'Light Mode' : 'Dark Mode'}
          css={{ position: 'relative' }}
        >
          <IconButton
            onClick={toggleColorMode}
            icon={isDark ? <TbSun /> : <TbMoon />}
            variant="outline"
          />
        </Tooltip>
      </Flex>

      {/* Mobile Content */}
      <Flex
        w="100vw"
        display={display}
        bgColor={isDark ? '#1a1917' : '#ffffff'}
        h="100%"
        pos="fixed"
        top="0"
        left="0"
        overflowY="auto"
        flexDir="column"
        zIndex={5000}
      >
        <Flex justify="flex-start">
          <IconButton
            variant="outline"
            ms="1.25rem"
            mt="0.95rem"
            aria-label="Open Menu"
            icon={<TbX />}
            onClick={() => changeDisplay('none')}
          />
        </Flex>

        <Flex flexDir="column" align="center" gap={10} zIndex={10}>
          <Link href="#home" onClick={() => changeDisplay('none')}>
            Home
          </Link>

          <Link href="#services" onClick={() => changeDisplay('none')}>
            Services
          </Link>

          <Link href="#about" onClick={() => changeDisplay('none')}>
            About
          </Link>
          <Link href="#contact" onClick={() => changeDisplay('none')}>
            Contact
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
